/* eslint-disable @next/next/no-img-element */
import styles from "@buddieshr/styles/HomeAlfy.module.scss";
import MetaHeader from "@buddieshr/components/metaHeader";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Header from "@buddieshr/components/header";
import Footer from "@buddieshr/components/footer";
import Image from "next/image";
import TextColored from "@buddieshr/components/text/textColored";
import AddToSlackButton from "@buddieshr/components/addToSlackButton";
import { Tag } from "antd";
import Features from "@buddieshr/components/app/features";
import Benefits from "@buddieshr/components/benefits";
import Quote from "@buddieshr/components/templates/quote";
import InstallNow from "@buddieshr/components/installNow";
import GetStarted from "@buddieshr/components/app/getStarted";
import G2Logos from "@buddieshr/components/g2Logos";
import TrustedBy from "@buddieshr/components/trustedBy";
import PageWrapper from "@buddieshr/components/templates/pageWrapper";
import { PRODUCTS } from "@buddieshr/utils/constants-products";
import PricingButtonHint from "@buddieshr/components/pricing/pricingbuttonHint";
import WatchADemoButton from "@buddieshr/components/alfy/watchADemoButton";
import BuddieshrAppList from "@buddieshr/components/buddieshrAppList";
import LogoApp from "@buddieshr/components/app/logoApp";

export default function Home() {
  const { t } = useTranslation();
  const router = useRouter();
  const app = PRODUCTS.find((c) => c.id === "Alfy");

  return (
    <>
      <MetaHeader
        title={t("metaTitleAlfy")}
        description={t("descriptionContentAlfy")}
        currentURL={`https://buddieshr.com/${
          router.locale === "en" ? "" : router.locale
        }/${app.slug}`}
        currentShare={`https://buddieshr.com/img/apps/${app.slug}/share_preview_1.png`}
        pageSlug={`${app.slug}`}
        locale={router.locale}
      />
      <Header app={app} />
      <main className={styles.main} style={{ background: app.backgroundColor }}>
        <PageWrapper width={1500}>
          <div className={styles.firstSectionWrapper}>
            <Image
              className={styles.mug}
              src="/img/apps/alfy/mug.svg"
              alt="Alfy Matching"
              width={50}
              height={50}
            />
            <Image
              className={styles.grains}
              src="/img/apps/alfy/grains.png"
              alt="Alfy Matching"
              width={50}
              height={50}
            />
            <LogoApp
              app={app}
              removeBack
              withText
              withSubtitle
              removeByBuddies
              wrapperStyle={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 36,
                maxWidth: "100%",
              }}
              style={{
                textAlign: "left",
                margin: "auto",
                border: "2px solid #0000000a",
                borderRadius: "10px",
                padding: "6px 12px",
                paddingBottom: "8px",
                transform: "scale(1.2)",
                maxWidth: "calc(100% - 40px)",
              }}
            />
            <div className={styles.tagWrapper}>
              <Tag color="blue" className={styles.tag}>
                CONNECT TEAMS
              </Tag>
            </div>
            <h1 className={styles.mainTitle}>
              Build <TextColored app={app}>better relationships</TextColored> at
              work
            </h1>
            <div className={styles.subtitle}>
              Strengthen team bonding by regularly connecting coworkers for a
              virtual chat&nbsp;☕️
            </div>
            {/* <div className={styles.ratedWrapper}>
              <Rated />
            </div> */}
            <div className={styles.actions}>
              <WatchADemoButton app={app} />
              <div style={{ maxWidth: "100%" }}>
                <AddToSlackButton app={app} />
                <div className={styles.hint}>
                  <PricingButtonHint />
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
        <PageWrapper style={{ paddingBottom: 0 }}>
          <div className={styles.picturesProductWrapper}>
            <div className={styles.picturesProductInner}>
              <div className={styles.picturesProductLeft}>
                <Image
                  src="/img/apps/alfy/product_screen.png"
                  alt="coffee in slack"
                  width={1042}
                  height={502}
                />
              </div>
              <div className={styles.picturesProductRight}>
                <Image
                  src="/img/apps/alfy/product_screen_2.png"
                  alt="coffee in slack"
                  width={1244}
                  height={220}
                />
              </div>
            </div>
          </div>
        </PageWrapper>
        <TrustedBy />
        <br />
        <br />
        <G2Logos />
        <PageWrapper>
          <div className={styles.benefitsWrapper}>
            <Benefits app={app} />
          </div>
        </PageWrapper>
        <div className={styles.quoteWrapper}>
          <Quote
            suffix={
              <div>
                According to a{" "}
                <a
                  href="https://s3.amazonaws.com/ghc-2018/GHC_Ch5.pdf"
                  target={"_blank"}
                  rel="noopener"
                >
                  global study
                </a>{" "}
                by the International Social Survey Program (ISSP)
              </div>
            }
          >
            “Relationships rank first out of…12 domains <br />
            of workplace quality in terms <br />
            of power to explain variation in job satisfaction.”
          </Quote>
        </div>
        <PageWrapper>
          <div className={styles.featureWrapper} id="features">
            <Features app={app} />
          </div>

          <GetStarted app={app} />
          <div className={styles.installWrapper}>
            <InstallNow
              app={app}
              gradient={app.gradient}
              title="Ready to Build better relationships at work?"
              subtitle="Impact your team today. Get started now."
            />
          </div>
        </PageWrapper>
        <div>
          <BuddieshrAppList appToHide={app.slug} />
        </div>
      </main>
      <Footer app={app} />
    </>
  );
}
